<template>
  <div id="buy-bitcoin">
    <loading :can-cancel="true"></loading>
    <ConfirmPurchaseRequest
      v-show="showBuyModal"
      @closeModal="toggleConfirmPurchaseRequest"
    />
    <LackOfInventory
      @closeModal="closeLackOfWalletModal"
      v-show="showLackOfWallet"
      :amount="amount"
      :total-price="tomman"
      :unit-price="item ? item.purchase_price_from_us : 0"
      :wallet-inventory="walletInventory.toLocaleString()"
    />
    <SuccessAlert
      title="فروش با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-if="showSuccessMassage"
    />
    <div class="bitcoin-container">
      <div class="amount-of-bitcoin-form-section">
        <div class="amount-of-bitcoin-form-section__header">
          مقدار {{ item ? item.persian_name : "----" }}*
        </div>
        <div class="bitcoin-amount-input">
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.amount }">
              <img
                :src="item ? item.image : '---'"
                class="amount-input__image"
                alt=""
              />
              <label>
                <input
                  type="text"
                  style="text-align: center"
                  @change="setAmount($event)"
                  ref="amountInput"
                  :value="amount"
                />
              </label>
              <div class="amount-input__symbol">
                {{ item ? item.symbol : "----" }}
              </div>
            </div>
            <div class="error-message">
              {{ error.amount }}
            </div>
          </div>
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.tomman }">
              <img
                src="../../assets/images/iran.svg"
                alt=""
                class="amount-input__image"
              />
              <label>
                <input
                  type="text"
                  style="text-align: center"
                  v-model="tomman"
                  @input="setToman($event)"
                  ref="tommanInput"
                />
              </label>
              <div class="amount-input__symbol">تومان</div>
            </div>
            <div class="error-message">
              {{ error.tomman }}
            </div>
          </div>
        </div>
        <div class="tomman-amount-input"></div>
        <div class="bitcoin-amount-details">
          <div class="bitcoin-amount-details__span">
            حداقل میزان سفارش : <span>۵۶۵</span>
          </div>
          <div class="bitcoin-amount-details__span">
            موجودی :
            <span
              >{{ item ? item.symbol : "----" }}
              {{ item ? item.inventory : "----" }}</span
            >
          </div>
          <div class="bitcoin-amount-details__span">
            موجودی تومانی :
            <span>{{
              item ? Number(item.tommani_inventory).toLocaleString() : "----"
            }}</span>
          </div>
        </div>
      </div>
      <div class="purchase-price-from-site">
        <div class="purchase-price-from-site_header">
          قیمت خرید از اکسچنج از
        </div>
        <div class="purchase-price-form-site_price">
          <span>{{
            item ? parseInt(item.purchase_price_from_us).toLocaleString() : 0
          }}</span>
          تومان
        </div>
      </div>
      <div class="transfer-fee-section">
        <div class="transfer-fee-wrapper">
          <div class="network-transfer-fee">
            کارمزد انتقال در شبکه : <span>{{ item ? item.wage : "----" }}</span>
          </div>
          <div class="net-received">خالص دریافتی : <span>۱.۱۹</span></div>
        </div>
      </div>
      <div class="wallet-address-section">
        <div class="wallet-address-wrapper">
          <div class="wallet-address-wrapper__header">
            آدرس کیف پول
          </div>
          <div class="wallet-address__input-wrapper">
            <label>
              <input
                :class="{ error: error.wallet_address }"
                class="wallet-address-input"
                type="text"
                v-model="wallet_address"
                @input="setWalletAddress($event.target.value)"
                ref="walletAddressInput"
              />
            </label>
          </div>
          <div class="error-message">
            {{ error.wallet_address }}
          </div>
        </div>
      </div>
      <div class="how-to-pay-section">
        <div class="how-to-pay__header">
          نحوه دریافت پول
        </div>
        <div class="how-to-pay__selected-section">
          <div class="how-to-pay__radio-wrapper">
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="huey"
                value="bank_gate"
                v-model="pay_method"
                checked
              />
              <label for="huey">درگاه بانکی</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="dewey"
                value="wallet"
                v-model="pay_method"
              />
              <label for="dewey"
                >کیف پول (موجودی:
                <span>{{ walletInventory.toLocaleString() }}</span
                >)</label
              >
            </div>
          </div>
          <div class="how-to-pay__information">
            با شارژ کیف پول میتوانید پرداخت های خود را بدون رفتن به درگاه بانکی
            پرداخت کنید.
          </div>
        </div>
        <div class="description-section">
          <div class="description-header">توضیحات</div>
          <label>
            <textarea
              class="description-textarea"
              name=""
              id=""
              v-model="description"
            ></textarea>
          </label>
        </div>
      </div>
      <div class="buy-button-wrapper">
        <BuyButton class="buy-button" :on-click="submit" :fontsize="true" />
      </div>
    </div>
  </div>
</template>

<!--@click.prevent="toggleConfirmPurchaseRequest"-->

<script>
import BuyButton from "@/components/Button/BuyButton";
import ConfirmPurchaseRequest from "@/components/Modals/ConfirmPurchaseRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
import LackOfInventory from "@/components/Modals/LackOfInventory";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "BuyCrypto",
  props: {
    item: {},
    walletInventory: {
      type: Number,
      required: true
    },
    propAmount: {
      default: 0
    },
    propTomman: {
      default: 0
    },
    propWalletAddress: {
      default: ""
    }
  },
  components: {
    LackOfInventory,
    SuccessAlert,
    ConfirmPurchaseRequest,
    BuyButton,
    Loading
  },
  data() {
    return {
      showBuyModal: false,
      showSuccessMassage: false,
      showLackOfWallet: false,
      amount: this.propAmount,
      tomman: this.propTomman,
      pay_method: "bank_gate",
      error: {},
      wallet_address: this.propWalletAddress,
      description: null
    };
  },
  // mounted() {
  //   console.log(this.$route.query.sell_amount);
  // },
  methods: {
    toggleConfirmPurchaseRequest() {
      this.showBuyModal = !this.showBuyModal;
      this.showSuccessMassage = false;
      if (!this.showBuyModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    closeLackOfWalletModal() {
      this.showLackOfWallet = false;
    },
    async submit() {
      this.error = {};
      if (this.amount === "0" || this.amount === null) {
        this.error.amount = "تعداد را وارد کنید";
        this.$refs.amountInput.focus();
        return false;
      }
      if (this.tomman === "0" || this.tomman === null) {
        this.error.tomman = "فیلد مبلغ نیمتواند خالی باشد";
        this.$refs.tommanInput.focus();
        return false;
      }
      if (this.propWalletAddress === undefined || !this.propWalletAddress) {
        this.error.wallet_address = "آدرس کیف پول خود را وارد کنید";
        this.$refs.walletAddressInput.focus();
        return false;
      }
      if (this.pay_method === "bank_gate") {
        console.log("go to gate");
        return false;
      }

      // axios
      let tomman = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));

      if (this.pay_method === "wallet") {
        if (this.walletInventory < tomman) {
          this.showLackOfWallet = true;
          return false;
        }

        let data = {
          crypto_id: this.item.id,
          cost: tomman,
          amount: this.amount,
          wallet_address: this.wallet_address,
          description: this.description
        };

        let loader = this.$loading.show();
        try {
          let response = await axios({
            url: `/panel/order?pay_method=wallet&type=buy&order_method=crypto`,
            method: "POST",
            data: data
          });

          // console.log(response.data);
          if (!response.data.status) {
            this.$toast.error(response.data.message);
          }
          this.$toast.success(response.data.message);
        } catch (error) {
          console.log(error);
        }
        loader.hide();
      }
    },
    // eslint-disable-next-line vue/no-dupe-keys
    setAmount(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else {
        value = value.replace(data, "");
      }

      this.amount = value;
      this.tomman = (this.item.purchase_price_from_us * value).toLocaleString();
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    },
    setToman(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^[0-9]+\.?[0-9]+$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else {
        value = value.replace(data, "");
      }

      this.tomman = value;
      this.amount = value / this.item.purchase_price_from_us;
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    },
    setWalletAddress() {
      this.$emit("value", {
        amount: this.amount,
        tomman: this.tomman,
        wallet_address: this.wallet_address
      });
    }
  },
  watch: {
    item() {
      this.setAmount(Number(this.propAmount));
    },
    propAmount() {
      this.amount = this.propAmount;
    }
  }
};
</script>

<style scoped>
.bitcoin-container {
  width: 100%;
  min-height: 743px;
  border-radius: 10px;
  background: rgba(3, 145, 0, 0.1);
  padding: 5px;
}

.bitcoin-amount-input {
  width: 100%;
}

.amount-input__wrapper {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 0 10px;
  outline: none;
  border: none;
  font-size: 16px;
}

.bitcoin-amount-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bitcoin-amount-details__span {
  font-weight: normal;
  font-size: 12px;
  color: rgba(18, 18, 18, 0.7);
  display: flex;
}

.amount-input__symbol {
  color: rgba(18, 18, 18, 0.7);
}

.amount-input__image {
  width: 22px;
  height: 22px;
}

.amount-of-bitcoin-form-section {
  margin-bottom: 20px;
}

.amount-of-bitcoin-form-section__header {
  color: #121212;
  font-size: 16px;
}

.purchase-price-from-site {
  margin-bottom: 20px;
}

.purchase-price-form-site_price {
  background: rgba(255, 255, 255, 0.5);
  text-align: end;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  color: rgba(18, 18, 18, 0.7);
}

.transfer-fee-section {
  margin-bottom: 20px;
}

.transfer-fee-section {
  width: 100%;
}

.transfer-fee-wrapper {
  width: 100%;
  background: #fff;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #6b88d1;
  gap: 25px;
}

.wallet-address-section {
  margin-bottom: 20px;
}

.wallet-address-wrapper__header {
  font-size: 16px;
  color: #121212;
}

.wallet-address-input {
  width: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 0 4px 12px;
  direction: ltr;
  color: rgba(18, 18, 18, 0.7);
}

.how-to-pay__header {
  font-size: 16px;
  color: #121212;
  margin-bottom: 30px;
}

.how-to-pay__radio-wrapper {
  display: flex;
  gap: 90px;
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

label {
  font-size: 14px;
}

.how-to-pay__information {
  font-size: 12px;
  margin-top: 10px;
  color: #121212;
}

.how-to-pay__selected-section {
  margin-bottom: 20px;
}

.description-textarea {
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 200px;
}

.description-header {
  font-weight: normal;
  font-size: 16px;
}

.buy-button {
  margin: 25px auto;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
}

.content {
  font-size: 20px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 768px) {
  .bitcoin-container {
    padding: 20px;
  }
}
</style>
