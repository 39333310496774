<template>
  <div id="sell-bitcoin">
    <loading :can-cancel="true"></loading>
    <TxidInformationModal
      v-show="showTxidInformation"
      @closeModal="toggleTxidInformation"
    />
    <ConfirmSalesRequest
      v-show="showBuyModal"
      @closeModal="toggleConfirmSalesRequest"
    />
    <SuccessAlert
      title="فروش با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-show="showSuccessMassage"
    />
    <div class="bitcoin-container">
      <div class="form-group">
        <div class="amount-of-bitcoin-form-section__header">
          مقدار {{ item ? item.persian_name : "----" }}*
        </div>
        <div class="bitcoin-amount-input">
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.amount }">
              <img
                :src="item ? item.image : '----'"
                class="amount-input__image"
                alt="crypto-icon"
              />
              <label>
                <input
                  type="text"
                  style="text-align: center; font-size: 16px"
                  v-model="amount"
                  @input="setAmount($event)"
                  ref="amountInput"
                />
              </label>
              <div class="amount-input__symbol">
                {{ item ? item.symbol : "----" }}
              </div>
            </div>
            <div class="error-message">
              {{ error.amount }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="purchase-price-from-site-wrapper">
          <div class="purchase-price-from-site_header">
            قیمت هر {{ item ? item.persian_name : "----" }}
          </div>
          <div class="purchase-price-form-site_price">
            <span>{{
              item ? Number(item.sale_price_from_us).toLocaleString() : 0
            }}</span>
            تومان
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="amount_received-wrapper">
          <div class="amount-received__header">
            مقدار دریافتی
          </div>
          <div class="amount-received__input">
            <span>
              <label>
                <input
                  type="text"
                  style="text-align: left; font-size: 16px"
                  v-model="tomman"
                  @input="setToman($event)"
                  ref="tommanInput"
                /> </label></span
            >تومان
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="exchange-wallet-address-wrapper">
          <div class="exchange-wallet-address__header">
            آدرس کیف پول اکسچنج از
          </div>
          <div class="exchange-wallet-address__input">
            <span>oifdjflkfhfvdljvndlvnldjvnldlgdgbngied</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="barcode__wrapper">
          <div class="barcode__image">
            <img src="../../assets/images/barcode.svg" alt="" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="tag-wallet-address-wrapper">
          <div class="tag-wallet-address__header">
            تگ آدرس کیف پول اکسچنج از
          </div>
          <div class="tag-wallet-address__input">
            ۰۴۸۵۹۸۹۵-۹۴۸۵۵۹
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="transaction-link-wrapper">
          <div class="transaction-link__header">
            لینک تراکنش (TxID)
          </div>
          <label>
            <input
              :class="{ error: error.txid }"
              type="text"
              class="transaction-link__input"
              v-model="txid"
              ref="txid"
            />
          </label>
          <div class="error-message">
            {{ error.txid }}
          </div>
          <div
            class="transaction-link__information-link"
            @click.prevent="toggleTxidInformation"
          >
            TxID چیست؟
          </div>
        </div>
      </div>

      <div class="form-group" style="margin-top: 40px;">
        <div class="how-to-get-money-wrapper">
          <div class="how-to-get-money__header">
            نحوه دریافت پول
          </div>
          <div class="how-to-get-money__radio-wrapper">
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="banking-portal"
                name="radio-group"
                value="wallet"
                v-model="picked"
                checked
              />
              <label for="banking-portal">انتقال به کیف پول</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="deposit-to-account"
                name="radio-group"
                value="bank_account"
                v-model="picked"
              />
              <label for="deposit-to-account">واریز به حساب بانکی</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="deposit-to-others-accounts"
                name="radio-group"
                value="other_account"
                v-model="picked"
              />
              <label for="deposit-to-others-accounts"
                >واریز به حساب دیگران</label
              >
            </div>
          </div>
          <div class="how-to-get-money__information-text">
            واریز به کیف پول داخلی اکس اونیکس به صورت آنی بوده و می توانید از آن
            برای خریدهای بعدی یا تبدیل به سایر ارزها استفاده نمایید.
          </div>
          <div class="how-to-get-money__check-radio-container">
            <TransferToWallet
              @modalChang="toggleConfirmSalesRequest"
              v-if="picked === 'wallet'"
              @input="handleTransferToWalletData"
              @onClick="submit"
            />
            <DepositToABankAccount
              @modalChang="toggleConfirmSalesRequest"
              v-if="picked === 'bank_account'"
              @value="handleDepositToBankAccountData"
              @bankSelected="handleDepositToBankAccountData"
              @onClick="submit"
            />
            <DepositToOthersAccounts
              @modalChang="toggleConfirmSalesRequest"
              v-if="picked === 'other_account'"
              @onClick="submit"
              @cardNumberInput="handleToOtherAccountCardNumber"
              @nameAndFamilyInput="handleToOtherAccountNameAndFamilyInput"
              @shabaNumber="handleToOtherAccountShabaNumber"
              @accountNumberInput="handleToOtherAccountNumberInput"
              @description="handleToOtherAccountDescription"
              @bankNameInput="handleToOtherBankName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TxidInformationModal from "@/components/Modals/TxidInformationModal";
import TransferToWallet from "@/components/TransferToWallet";
import DepositToABankAccount from "@/components/DepositToABankAccount";
import DepositToOthersAccounts from "@/components/DepositToOthersAccounts";
import ConfirmSalesRequest from "@/components/Modals/ConfirmSalesRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "SellCrypto",
  props: {
    item: {},
    propAmount: {
      details: 0
    },
    propTomman: {
      default: 0
    }
  },
  components: {
    ConfirmSalesRequest,
    DepositToOthersAccounts,
    DepositToABankAccount,
    TransferToWallet,
    TxidInformationModal,
    SuccessAlert,
    Loading
  },
  data() {
    return {
      showTxidInformation: false,
      picked: "wallet",
      transferToWalletDescription: null,
      DepositToABankAccountDescription: null,
      DepositToABankAccountBankSelected: null,
      DepositToOthersAccounts: null,
      DepositToOthersAccounts_cardNumber: null,
      DepositToOthersAccounts_familyInput: null,
      DepositToOthersAccounts_shabaNumber: null,
      DepositToOthersAccounts_accountNumber: null,
      DepositToOthersAccountsBankName: null,
      showBuyModal: false,
      showSuccessMassage: false,
      amount: this.propAmount,
      tomman: this.propTomman,
      txid: null,
      error: {}
    };
  },

  methods: {
    async submit() {
      this.error = {};
      if (this.picked === "wallet") {
        if (this.amount == "0" || this.amount === null) {
          this.error.amount = "تعداد را وارد کنید";
          this.$refs.amountInput.focus();
          return false;
        }

        if (this.tomman === "0" || this.tomman === null) {
          this.error.tomman = "فیلد مبلغ نیمتواند خالی باشد";
          this.$refs.tommanInput.focus();
          return false;
        }

        if (!this.txid) {
          this.error.txid = "ترانزاکشن آیدی را وارد کنید";
          this.$refs.txid.focus();
          return false;
        }

        let cost = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));
        let data = {
          crypto_id: this.item.id,
          amount: this.amount,
          cost: cost,
          txid: this.txid,
          description: this.transferToWalletDescription
        };

        let loader = this.$loading.show();
        try {
          let response = await axios({
            url: `/panel/order?type=sell&pay_method=${this.picked}&order_method=crypto`,
            method: "POST",
            data: data
          });
          if (response.data.status) {
            this.$toast.success(response.data.message);
          }
        } catch (error) {
          console.log(error);
        }

        this.amount = null;
        this.tomman = null;
        this.txid = null;
        this.description = null;
        loader.hide();
      }

      if (this.picked === "bank_account") {
        if (this.amount == "0" || this.amount === null) {
          this.error.amount = "تعداد را وارد کنید";
          this.$refs.amountInput.focus();
          return false;
        }

        if (this.tomman === "0" || this.tomman === null) {
          this.error.tomman = "فیلد مبلغ نیمتواند خالی باشد";
          this.$refs.tommanInput.focus();
          return false;
        }

        if (!this.txid) {
          this.error.txid = "ترانزاکشن آیدی را وارد کنید";
          this.$refs.txid.focus();
          return false;
        }

        let cost = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));
        let data = {
          crypto_id: this.item.id,
          amount: this.amount,
          cost: cost,
          txid: this.txid,
          description: this.DepositToABankAccountDescription,
          bank_account_id: this.DepositToABankAccountBankSelected.id
        };
        let loader = this.$loading.show();
        try {
          let response = await axios({
            url:
              "/panel/order?type=sell&pay_method=bank_account&order_method=crypto",
            method: "POST",
            data: data
          });
          if (!response.data.status) {
            this.$toast.error(response.data.message);
            return false;
          }
          this.$toast.success(response.data.message);
        } catch (error) {
          console.log(error);
        }

        loader.hide();
      }

      if (this.picked === "other_account") {
        let cost = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));
        let data = {
          crypto_id: this.item.id,
          amount: this.amount,
          cost: cost,
          txid: this.txid,
          description: this.DepositToABankAccountDescription,
          card_number: this.DepositToOthersAccounts_cardNumber,
          fullname: this.DepositToOthersAccounts_familyInput,
          bank_name: this.DepositToOthersAccountsBankName,
          shaba_number: this.DepositToOthersAccounts_shabaNumber,
          account_number: this.DepositToOthersAccounts_accountNumber
        };
        console.log("other account", data);
        let loader = this.$loading.show();
        try {
          let response = await axios({
            url:
              "/panel/order?type=sell&pay_method=other_account&order_method=crypto",
            method: "POST",
            data: data
          });
          console.log(response.data);
          if (response.data.status) {
            this.$toast.error(response.data.message);
            return false;
          }
          this.$toast.success(response.data.message);
        } catch (error) {
          console.log(error);
        }

        loader.hide();
      }
    },
    toggleTxidInformation() {
      this.showTxidInformation = !this.showTxidInformation;
    },
    toggleConfirmSalesRequest() {
      this.showBuyModal = !this.showBuyModal;
      this.showSuccessMassage = false;
      if (!this.showBuyModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    setAmount(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else value = value.replace(data, "");

      this.amount = value;
      this.tomman = (this.item.sale_price_from_us * value).toLocaleString();
      this.$emit("value", {
        amount: this.amount,
        tomman: this.tomman
      });
    },
    setToman(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else {
        value = value.replace(data, "");
      }

      this.tomman = value;
      this.amount = value / this.item.sale_price_from_us;
      this.$emit("value", {
        amount: this.amount,
        tomman: this.tomman,
        description: this.description
      });
    },
    handleDepositToBankAccountData(item) {
      if (typeof item == "string") {
        this.DepositToABankAccountDescription = item;
      }
      if (typeof item == "object") {
        this.DepositToABankAccountBankSelected = item;
      }
    },
    handleTransferToWalletData(item) {
      // console.log(item);
      this.transferToWalletDescription = item;
    },
    handleToOtherAccountCardNumber(item) {
      this.DepositToOthersAccounts_cardNumber = item;
    },
    handleToOtherAccountNameAndFamilyInput(item) {
      this.DepositToOthersAccounts_familyInput = item;
    },
    handleToOtherAccountShabaNumber(item) {
      this.DepositToOthersAccounts_shabaNumber = item;
    },
    handleToOtherAccountNumberInput(item) {
      this.DepositToOthersAccounts_accountNumber = item;
    },
    handleToOtherAccountDescription(item) {
      this.DepositToABankAccountDescription = item;
    },
    handleToOtherBankName(item) {
      this.DepositToOthersAccountsBankName = item;
    }
  },
  watch: {
    item() {
      this.setAmount(Number(this.propAmount));
    },
    propAmount() {
      this.amount = this.propAmount;
    }
  }
};
</script>

<style scoped>
.bitcoin-container {
  width: 100%;
  /*min-height: 1090px;*/
  border-radius: 10px;
  background: rgba(220, 0, 0, 0.1);
  /*padding: 20px;*/
  padding: 5px;
}

.bitcoin-amount-input {
  width: 100%;
}

.amount-input__wrapper {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 0 10px;
  /* height: 100%; */
  outline: none;
  border: none;
}

.amount-input__symbol {
  color: rgba(18, 18, 18, 0.7);
}

.amount-input__image {
  width: 22px;
  height: 22px;
}

.amount-of-bitcoin-form-section__header {
  color: #121212;
  font-size: 16px;
}

.form-group {
  margin-bottom: 20px;
}

.purchase-price-form-site_price {
  background: rgba(255, 255, 255, 0.5);
  text-align: end;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  color: rgba(18, 18, 18, 0.7);
}

.amount-received__header {
  font-weight: normal;
  font-size: 16px;
}

.amount-received__input {
  width: 100%;
  background: #fff;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  text-align: left;
}

.exchange-wallet-address__header {
  font-size: 16px;
  font-weight: normal;
}

.exchange-wallet-address__input {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  /*padding: 3px 0 3px 27px;*/
  border-radius: 5px;
  padding: 3px;
  text-align: left;
  color: rgba(18, 18, 18, 0.7);
}

.exchange-wallet-address__input span {
  font-size: 12px;
}

.barcode__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tag-wallet-address__input {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  text-align: left;
  color: rgba(18, 18, 18, 0.7);
}

.transaction-link__header {
  font-size: 16px;
  font-weight: normal;
}

.transaction-link__input {
  width: 100%;
  padding: 5px 10px 5px 27px;
  border-radius: 5px;
}

.transaction-link__information-link {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: rgba(31, 60, 136, 0.8);
  float: left;
  margin-top: 5px;
  cursor: pointer;
}

.txid-information-modal-wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  right: 50%;
}

.how-to-get-money__radio-wrapper {
  display: flex;
  /*gap: 45px;*/
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.how-to-get-money__header {
  font-weight: normal;
  font-size: 16px;
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 10px;
}

label {
  font-weight: normal;
  font-size: 14px;
}

.how-to-get-money__information-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  margin-top: 10px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 720px) {
  .bitcoin-container {
    padding: 20px;
  }
}
</style>
