<template>
  <div id="digitalCurrencyEquations">
    <div class="header">
      <PanelHeader title="معاملات رمز ارزها" />
    </div>
    <div class="digital-currency-equations-container">
      <div class="top-slider">
        <div class="slider-wrapper">
          <hooper
            style="height: 100%"
            :settings="hooperSettings"
            ref="hooperSlider"
          >
            <slide v-for="(item, i) in slider_item" :key="i">
              <div
                class="slider-card-wrapper"
                @click="sliderClickHandler(i, item)"
              >
                <div class="shadow-image">
                  <img :src="item.image" alt="" class="image-slider" />
                </div>
                <div class="slider-title">{{ item.persian_name }}</div>
              </div>
            </slide>
            <!--            <hooper-pagination slot="hooper-addons"></hooper-pagination>-->
          </hooper>
        </div>
      </div>
      <div class="second-row">
        <div class="column-one">
          <div class="tab-card">
            <div class="tab-header" :class="[left ? 'left' : '']">
              <div class="right-tab" @click="toggleRightSide">
                خرید {{ selectedItem ? selectedItem.persian_name : "----" }}
              </div>
              <div class="left-tab" @click="toggleLeftSide">
                فروش {{ selectedItem ? selectedItem.persian_name : "----" }}
              </div>
            </div>
            <div class="tab-right-content" v-show="!left">
              <div class="right-tab-content-wrapper">
                <BuyCrypto
                  @value="setValueBuyCrypto"
                  :item="selectedItem"
                  :propAmount="buyAmount"
                  :propTomman="tomman"
                  :prop-wallet-address="wallet_address"
                  :wallet-inventory="wallet ? parseInt(wallet) : 0"
                />
              </div>
            </div>
            <div class="tab-left-content" v-show="left">
              <div class="left-tab-content-wrapper">
                <SellCrypto
                  @value="setValueSellCrypto"
                  :item="selectedItem"
                  :propAmount="sellAmount"
                  :propTomman="tomman"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column-two">
          <div class="information-wrapper">
            <TipsAndWarnings />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import TipsAndWarnings from "@/components/TipsAndWarnings";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import BuyCrypto from "@/components/DigitalCurrencyCard/BuyCrypto";
import SellCrypto from "@/components/DigitalCurrencyCard/SellCrypto";
import axios from "axios";

export default {
  components: {
    SellCrypto,
    BuyCrypto,
    TipsAndWarnings,

    PanelHeader,
    Hooper,
    Slide
  },
  data() {
    return {
      left: false,
      buyAmount: 0,
      sellAmount: 0,
      tomman: null,
      txid: null,
      description: "",
      wallet_address: null,
      slider_item: [],
      selectedItem: null,
      slider_index: 0,
      wallet: null,
      hooperSettings: {
        rtl: true,
        itemsToShow: 3,
        infiniteScroll: false,
        centerMode: true,
        itemsToSlide: 8,
        breakpoints: {
          200: {
            itemsToShow: 1
          },
          300: {
            itemsToShow: 3
          },
          576: {
            itemsToShow: 5
          },
          992: {
            itemsToShow: 6
          },
          1200: {
            itemsToShow: 8
          }
        }
      }
    };
  },
  async mounted() {
    this.buyAmount = this.$route.query.buy_amount;
    this.sellAmount = this.$route.query.sell_amount;
    this.left = this.$route.query.section === "sell";

    await this.getAllCrypto();
    await this.getWallet();

    this.selectedItem = this.slider_item[0];

    const sliderItemIndex = this.slider_item.findIndex(i => {
      return i.id == this.$route.query.coin;
    });

    if (sliderItemIndex >= 0) {
      this.selectedItem = this.slider_item[sliderItemIndex];
      this.$refs.hooperSlider.slideTo(sliderItemIndex);
    }

    const wrappers = document.querySelectorAll(".hooper-slide");
    wrappers.forEach((w, i) => {
      w.addEventListener("click", () => {
        this.$refs.hooperSlider.slideTo(i);
      });
    });
  },
  methods: {
    setValueBuyCrypto(value) {
      this.buyAmount = value.amount;
      this.tomman = value.tomman;
      this.wallet_address = value.wallet_address;
      this.description = value.description;
    },
    setValueSellCrypto(value) {
      if (value.amount !== undefined) this.sellAmount = value.amount;

      if (value.tomman !== undefined) this.tomman = value.tomman;

      if (value.txid !== undefined) this.txid = value.txid;

      if (value.description !== undefined) this.description = value.description;
    },
    toggleRightSide() {
      this.left = false;
    },
    toggleLeftSide() {
      this.left = true;
    },
    sliderClickHandler(index, item) {
      // console.log(index);
      this.selectedItem = item;
      this.slider_index = index;
    },
    async getAllCrypto() {
      let loading = this.$loading.show();
      try {
        let response = await axios("/crypto?type=crypto");
        this.slider_item = response.data;
      } catch (error) {
        console.log(error);
      }
      loading.hide();
    },
    async getWallet() {
      try {
        let response = await axios("/panel/wallet");
        this.wallet = response.data.balance;
      } catch (error) {
        console.log(error);
      }
    },
    calculateTomanInventory() {
      // console.log(this.selectedItem.inventory);
    }
  }
};
</script>

<style scoped>
#digitalCurrencyEquations {
  width: 100%;
  margin-bottom: 20px;
}

.digital-currency-equations-container {
  padding: 0 10px;
}

.header {
  margin-bottom: 20px;
}

.top-slider {
  width: 100%;
  min-height: 168px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: #ffffff;
}

.slider-wrapper {
  min-height: 168px;
  display: flex;
  gap: 70px;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 81px;
}

.name {
  margin-top: 15px;
  font-weight: normal;
  font-size: 16px;
  color: rgba(18, 18, 18, 0.6);
}

.item-image {
  width: 60px;
}

.second-row {
  margin-top: 20px;
}

.tab-card {
  width: 100%;
  height: auto;
  /*min-height: 878px;*/
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*padding: 0 20px;*/
  padding: 0 5px;
}

.tab-header {
  display: flex;
  padding: 30px 0 13px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  /*width: 100%;*/
}

.tab-header::before {
  transition: right 0.2s linear, background-color 0.2s linear;
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background-color: #039100;
  position: absolute;
  bottom: 0;
  right: 0;
  /*border-bottom: 5px solid #1F3C88;*/
  border-radius: 5px;
  /*background: #DC0000;*/
}

.tab-header::before {
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background: #039100;
}

.tab-header.left::before {
  right: 50%;
  background-color: #dc0000;
}

.tab-header::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(3, 145, 0, 0.2);
  position: absolute;
  bottom: 1px;
}

.tab-right-content {
  /*padding: 20px 45px;*/
  padding: 5px 5px;
}

.tab-left-content {
  /*padding: 20px 45px;*/
  padding: 5px;
}

.second-row {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

.column-one {
  width: 100%;
}

.column-two {
  width: 100%;
}

.right-tab {
  width: 50%;
}

.left-tab {
  width: 50%;
}

.slider-title {
  color: rgba(18, 18, 18, 0.6);
}

.information-wrapper {
  /*width: 100%;*/
  /*min-height: 1149px;*/
  /*background: #ffffff;*/
  /*box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);*/
  /*border-radius: 10px;*/
}

/*.right-tab-content-wrapper {*/
/*  width: 100%;*/
/*  min-height: 743px;*/
/*  background: rgba(3, 145, 0, 0.1);*/
/*  border-radius: 10px;*/
/*}*/

.image-slider {
  width: 60px;
  height: 60px;
}

.is-current .shadow-image {
  transform: scale(1.5);
}

.is-current .slider-title {
  margin-top: 5px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.is-current .shadow-image {
  position: relative;
  padding-bottom: 5px;
}

.is-current .shadow-image::before {
  position: absolute;
  content: "";
  height: 6px;
  width: 100%;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  background: #121212;
  opacity: 0.2;
  filter: blur(1px);
}

.slider-card-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 168px;
}

.panel-footer {
  margin-top: 20px;
}

/*.slider {*/
/*  width: 100%;*/
/*}*/

/*.slider-image {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  gap: 15px;*/
/*}*/

.show {
  width: calc(100% - 250px) !important;
  margin-right: auto;
}

@media (min-width: 992px) {
  #digitalCurrencyEquations {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .digital-currency-equations-container {
    padding: 0 40px;
  }

  .column-one {
    width: 100%;
  }

  .column-two {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .tab-right-content {
    padding: 20px 45px;
  }

  .tab-left-content {
    padding: 20px 45px;
  }
}

@media (min-width: 1200px) {
  .column-one {
    width: 58%;
  }

  .column-two {
    width: 39%;
  }

  .tab-card {
    padding: 0 20px;
  }
}

/*@media (min-width: 960px) {*/
/*  .column-one {*/
/*    width: 58%;*/
/*  }*/

/*  .column-two {*/
/*    width: 38%;*/
/*  }*/

/*  .tab-card {*/
/*    padding: 0 20px;*/
/*  }*/
/*}*/
</style>
